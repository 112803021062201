import * as React from "react";
import "../styles/globals.css";
import "./index.scss";

import { StaticImage } from "gatsby-plugin-image";

import NavbarWeb from "../components/navbarWeb";
import MobileNav from "../components/navbarMobile";
import Footer from "../components/footer";

import Carousel from "../components/menuCarousel";

const IndexPage = () => {
	return (
		<main id="Home">
			<NavbarWeb></NavbarWeb>
			<div className="mobileNavContainer">
				<div className="navLogo">
					<StaticImage
						src="../images/Kyoto-Brush-White.png"
						placeholder="blurred"
						width={346}
						height={720}
						formats={["auto", "webp", "avif"]}
						alt="Kyoto Garden Japanese Restaurant"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
				<MobileNav></MobileNav>
			</div>

			<header className="hero-banner">
				<h1>
					kyoto garden brings a beloved Japanese restaurant style to Cape Town.
				</h1>
				<div className="image-container">
					<StaticImage
						src="../images/Koshi-dark.jpg"
						placeholder="tracedSVG"
						width={1250}
						height={835}
						formats={["auto", "webp", "avif"]}
						alt="Koshi sushi chef"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
			</header>
			<section>
				<div className="two-grid first">
					<div className="large-text">
						Award-winning contemporary Japanese restaurant located in the heart
						of Cape Town.
					</div>
					<div className="small-text">
						Kyoto Garden is an award-winning Japanese restaurant located in a
						trendy part of Cape Town bustling with a vibrant nightlife. Once you
						step inside, however, the noise of the outside world ceases and
						you’re invited into an oasis of calm which is beautifully decorated
						and perfectly lit...
					</div>
				</div>
			</section>

			<section>
				<div className="two-grid cozy-grid">
					<div className="left text">
						<h1>A Cozy Escape</h1>
						<p>
							This shrine to Japanese cuisine offers much more than sushi and
							sashimi. At Kyoto Garden, fresh seafood is the specialty, with
							top-quality, unusual ingredients sourced from around the world,
							from farmed abalone to Mozambican conch, eel, sea urchin, and even
							Alaskan king crab, deep-sea scallops and wild salmon.
						</p>
					</div>
					<div className="right">
						<div className="image-container image1">
							<StaticImage
								src="../images/inside_shot.jpg"
								placeholder="blurred"
								width={680}
								height={382}
								formats={["auto", "webp", "avif"]}
								alt="Interior of a Modern Cape Town Sushi Restaurant"
								transformOptions={{ fit: "cover", cropFocus: "attention" }}
							/>
						</div>
					</div>
				</div>
				<div className="two-grid whisky-grid">
					<div className="left">
						<div className="image-container image1">
							<StaticImage
								src="../images/whisky.jpg"
								placeholder="blurred"
								width={680}
								height={382}
								formats={["auto", "webp", "avif"]}
								alt="South African Whisky Pairings with Sushi"
								transformOptions={{ fit: "cover", cropFocus: "attention" }}
							/>
						</div>
					</div>
					<div className="right text">
						<h1>Authentic Drinks</h1>
						<p>
							Kyoto Garden restaurant also boasts South Africa’s largest
							collection of Japanese whisky and is renowned for authentic
							cocktails, including the popular Dirty Ninja Saketini. Step inside
							and discover why Kyoto Garden was awarded the 2018 Eat Out
							accolade for Best Seafood Restaurant in Cape Town.
						</p>
					</div>
				</div>
			</section>

			<div className="awards-grid">
				<div className="image-container image1">
					<StaticImage
						src="../images/Rossouws.png"
						placeholder="blurred"
						width={125}
						height={125}
						formats={["auto", "webp", "avif"]}
						alt="Award Winning Rossouws"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
				<div className="image-container image1">
					<StaticImage
						src="../images/Eat-Out-2014-Award.png"
						placeholder="blurred"
						width={125}
						height={125}
						formats={["auto", "webp", "avif"]}
						alt="Eat Out Award High Reviews"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
			</div>

			<div className="carousel-container">
				<Carousel></Carousel>
			</div>

			<section class="info-section">
				<div className="left"></div>
				<div className="middle"></div>
				<div className="right"></div>
			</section>
			<Footer></Footer>
		</main>
	);
};

export default IndexPage;

export const Head = () => (
	<>
		<title>Kyoto Garden in Cape Town | Award-Winning Japanese Restaurant</title>
		<meta
			name="description"
			content="Experience the best of Japanese and sushi cuisine in Cape Town at Kyoto Garden, a multi award-winning restaurant located in the heart of the city."
		/>
	</>
);
